import { useMemo, type MouseEvent } from 'react'
import * as d3 from 'd3'
import { t } from 'i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { formatNumber, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'

export type DoubleDonutItem = {
  name: string
  value: number
  originalValue?: number
  percent?: number
  children?: DoubleDonutItem[]
}

type TooltipData = DoubleDonutItem & {
  color: string
}

const mockData: DoubleDonutItem[] = [
  {
    name: 'MyCollectables',
    value: 5000,
    children: [
      { name: 'MyCol', value: 4000 },
      { name: 'Collectables', value: 1000 }
    ]
  },
  {
    name: 'MyFinances',
    value: 3000,
    children: [
      { name: 'MyFin', value: 2500 },
      { name: 'Finances', value: 500 }
    ]
  },
  {
    name: 'MyProperties',
    value: 2000,
    children: [
      { name: 'MyPro', value: 200 },
      { name: 'Properties', value: 200 },
      { name: 'test', value: 1600 }
    ]
  },
  {
    name: 'MyBelongings',
    value: 1000,
    children: [{ name: 'MyBelongings', value: 1000 }]
  }
]

const emptyData: DoubleDonutItem[] = [
  {
    name: 'Mock 1',
    value: 60,
    children: [
      { name: 'Mock 1-1', value: 30 },
      { name: 'Mock 1-2', value: 20 },
      { name: 'Mock 1-3', value: 10 }
    ]
  },
  {
    name: 'Mock 2',
    value: 25,
    children: [
      { name: 'Mock 2-1', value: 15 },
      { name: 'Mock 2-2', value: 10 }
    ]
  },
  {
    name: 'Mock 3',
    value: 20,
    children: [
      { name: 'Mock 3-1', value: 10 },
      { name: 'Mock 3-2', value: 10 }
    ]
  },
  {
    name: 'Mock 4',
    value: 5,
    children: [{ name: 'Mock 4-1', value: 5 }]
  }
]

const donutWidth = {
  inner: 30,
  outer: 20
}

const MINIMUM_PERCENTAGE = 0.01

interface DoubleDonutChartProps {
  data?: DoubleDonutItem[]
  unit?: string
  width?: number
  height?: number
  className?: string
  labelClassName?: string
  formatter?: (value: string, unit: string) => string
}

export function DoubleDonutChart({
  data = [],
  unit = 'USD',
  height = 240,
  className,
  labelClassName,
  formatter = (value, unit) => `${unit} ${value}`
}: DoubleDonutChartProps) {
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.every(({ value }) => value === 0)
  const dataSource = (isEmpty ? emptyData : data).sort((a, b) => Math.abs(b.value) - Math.abs(a.value))
  const colors = isEmpty ? emptyColors : chartColors
  const total = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const adjustAngleList = useMemo(
    () =>
      dataSource.reduce((arr, item) => {
        if (item.value === 0) return arr
        const value = Math.abs(item.value)
        const minValue = Math.abs(total * MINIMUM_PERCENTAGE)
        return [...arr, Math.max(value, minValue)]
      }, [] as number[]),
    [dataSource, total]
  )
  const innerPie = useMemo(() => d3.pie<number>().value((d) => d)(adjustAngleList), [adjustAngleList])

  const handleMouseMove = (e: MouseEvent<SVGPathElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <div className={cn('flex justify-center', className)}>
      <ResponsiveContainer width={'100%'} height={height}>
        {({ width, height }) => {
          const outerRadius = Math.min(width, height) * 0.5
          const middleRadius = outerRadius - donutWidth.outer
          const innerRadius = middleRadius - donutWidth.inner

          return (
            <svg width={width} height={height}>
              {/* path item */}
              <g transform={`translate(${width / 2}, ${height / 2})`}>
                {innerPie.map(({ startAngle, endAngle, data }, innerIndex) => {
                  const color = colors[innerIndex % colors.length]
                  const innerPath = d3.arc()({
                    startAngle,
                    endAngle,
                    innerRadius,
                    outerRadius: middleRadius
                  })
                  const outerPie = d3
                    .pie<DoubleDonutItem>()
                    .sort(null)
                    .startAngle(startAngle)
                    .endAngle(endAngle)
                    .value((d) => Math.abs(d.value))(dataSource[innerIndex].children ?? [])
                  return (
                    <g key={`arc-${innerIndex}`}>
                      {outerPie.map(({ data, startAngle, endAngle }, outerIndex) => {
                        const outerPath = d3.arc()({
                          startAngle,
                          endAngle,
                          innerRadius,
                          outerRadius
                        })
                        return (
                          <path
                            key={`arc-${innerIndex}-${outerIndex}`}
                            className={cn(isEmpty && 'pointer-events-none')}
                            d={outerPath ?? ''}
                            fill={color}
                            opacity={0.7}
                            stroke={'#fff'}
                            strokeWidth={1}
                            onMouseMove={(e) => handleMouseMove(e, { ...data, color })}
                            onMouseLeave={hideTooltip}
                          />
                        )
                      })}
                      <path
                        className={cn(isEmpty && 'pointer-events-none')}
                        d={innerPath ?? ''}
                        fill={color}
                        stroke={'#fff'}
                        strokeWidth={1}
                        onMouseMove={(e) => handleMouseMove(e, { ...dataSource[innerIndex], color })}
                        onMouseLeave={hideTooltip}
                      />
                    </g>
                  )
                })}
              </g>
              {/* center label */}
              <g transform={`translate(${width / 2}, ${height / 2})`}>
                {isEmpty ? (
                  <text y={6} textAnchor={'middle'} fontSize={'16'}>
                    {t('NoData')}
                  </text>
                ) : (
                  <text className={labelClassName} y={6} textAnchor={'middle'} fontSize={'16'}>
                    {formatter(
                      formatNumber(total, preferences.numberFormat, {
                        digits: total >= 1e9 ? 2 : 0
                      }),
                      unit
                    )}
                  </text>
                )}
              </g>
            </svg>
          )
        }}
      </ResponsiveContainer>
      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <div className={'flex items-center justify-between'}>
            <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
              <div className={'my-0.5 h-4 w-4 shrink-0 rounded'} style={{ backgroundColor: tooltipData.data.color }} />
              <TruncatedText as={'span'} className={'text-sm font-bold capitalize text-white'}>
                {tooltipData.data.name}
              </TruncatedText>
            </div>
            {tooltipData.data.originalValue && (
              <span className={'shrink-0 text-sm font-bold text-white'}>
                {`${formatNumberShort(tooltipData.data.originalValue)}`}
              </span>
            )}
          </div>
          <div className={'flex items-center justify-between'}>
            {tooltipData.data.percent && (
              <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                {`${(tooltipData.data.percent * 100).toFixed(2)}%`}
              </TruncatedText>
            )}
            <span className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
              {formatter(formatNumberShort(tooltipData.data.value), unit)}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  )
}
