import { useMemo, type MouseEvent } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { formatNumber, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import { getTopSix } from '@/components/chart'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'

export type DonutItem = {
  name: string
  value: number
}

type TooltipData = DonutItem & { color: string }

const mockData: DonutItem[] = [
  { name: 'MyCollectables', value: 5000 },
  { name: 'MyFinances', value: 3000 },
  { name: 'MyProperties', value: 2000 },
  { name: 'MyBelongings', value: 1000 }
]

const emptyData: DonutItem[] = [
  { name: 'Mock 1', value: 60 },
  { name: 'Mock 2', value: 25 },
  { name: 'Mock 3', value: 20 },
  { name: 'Mock 4', value: 5 }
]

interface DonutChartProps {
  data?: DonutItem[]
  unit?: string
  width?: number
  height?: number
  donutWidth?: number
  label?: string
  labelClassName?: string
  formatter?: (value: string, unit: string) => string
}

const MINIMUM_PERCENTAGE = 0.01

export function DonutChart({
  data = [],
  unit = 'USD',
  height = 240,
  donutWidth = 40,
  label,
  labelClassName,
  formatter = (value, unit) => `${unit} ${value}`
}: DonutChartProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.every(({ value }) => value === 0)
  const dataSource = getTopSix(isEmpty ? emptyData : data, t('Others'))
  const colors = isEmpty ? emptyColors : chartColors
  const total = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const adjustAngleList = useMemo(
    () =>
      dataSource.reduce((arr, item) => {
        if (item.value === 0) return arr
        const value = Math.abs(item.value)
        const minValue = Math.abs(total * MINIMUM_PERCENTAGE)
        return [...arr, Math.max(value, minValue)]
      }, [] as number[]),
    [dataSource, total]
  )
  const pie = useMemo(() => d3.pie<number>().value((d) => d)(adjustAngleList), [adjustAngleList])
  const toolTipPercent = ((tooltipData?.data.value ?? 0) / total) * 100

  const handleMouseMove = (e: MouseEvent<SVGPathElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <div className={'flex justify-center'}>
      <ResponsiveContainer width={'100%'} height={height}>
        {({ width, height }) => {
          const outerRadius = Math.min(width, height) * 0.5
          const innerRadius = outerRadius - donutWidth

          return (
            <svg width={width} height={height}>
              {/* path item */}
              <g transform={`translate(${width / 2}, ${height / 2})`}>
                {pie.map(({ startAngle, endAngle }, index) => (
                  <path
                    key={`arc-${index}`}
                    className={cn(isEmpty && 'pointer-events-none')}
                    d={d3.arc()({ startAngle, endAngle, innerRadius, outerRadius }) ?? ''}
                    fill={colors[index % colors.length]}
                    stroke={'#fff'}
                    strokeWidth={1}
                    onMouseMove={(e) =>
                      handleMouseMove(e, { ...dataSource[index], color: colors[index % colors.length] })
                    }
                    onMouseLeave={hideTooltip}
                  />
                ))}
              </g>
              <foreignObject className={'pointer-events-none'} x={0} y={0} width={'100%'} height={'100%'}>
                <div className={'flex h-full items-center justify-center'}>
                  <p
                    className={cn('text-center font-bold', total < 0 && 'text-error', labelClassName)}
                    style={{ maxWidth: width / 2 }}
                  >
                    {isEmpty
                      ? t('NoData')
                      : label ??
                        formatter(
                          formatNumber(total, preferences.numberFormat, {
                            digits: total >= 1e9 ? 2 : 0
                          }),
                          unit
                        )}
                  </p>
                </div>
              </foreignObject>
            </svg>
          )
        }}
      </ResponsiveContainer>

      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <div className={'flex items-center justify-between'}>
            <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
              <div className={'my-0.5 h-4 w-4 shrink-0 rounded'} style={{ backgroundColor: tooltipData.data.color }} />
              <TruncatedText as={'span'} className={'text-sm font-bold capitalize text-white'}>
                {tooltipData.data.name}
              </TruncatedText>
            </div>
          </div>
          <div className={'flex items-center justify-between'}>
            {toolTipPercent && (
              <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                {`${toolTipPercent.toFixed(1)}%`}
              </TruncatedText>
            )}
            <span className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
              {formatter(formatNumberShort(tooltipData.data.value), unit)}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  )
}
