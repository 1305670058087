import { MouseEvent, useMemo } from 'react'
import * as d3 from 'd3'
import { useTranslation } from 'react-i18next'

import { chartColors, emptyColors } from '@/constants/chartConfig'
import { capitalize, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import TruncatedText from '@/components/base/TruncatedText'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'

export type VerticalBarItem = {
  name: string
  value: number
}

type TooltipData = VerticalBarItem & {
  color: string
}

const mockData: VerticalBarItem[] = [
  { name: 'Before 1970', value: -7000 },
  { name: '1970-1979', value: 2000 },
  // { name: '1980-1989', value: 6000 },
  { name: '1990-1999', value: -3000 },
  // { name: '2000-2009', value: 2000 },
  { name: 'After 2010', value: 4700 }
]

const emptyData: VerticalBarItem[] = [
  { name: 'Mock 1', value: 2000000 },
  { name: 'Mock 2', value: 8000000 },
  { name: 'Mock 3', value: 6000000 },
  { name: 'Mock 4', value: 4000000 },
  { name: 'Mock 5', value: 3000000 },
  { name: 'Mock 6', value: 9000000 }
]

const sortOrder = ['Before 1970', '1970-1979', '1980-1989', '1990-1999', '2000-2009', 'After 2010']

const MARGIN = { top: 28, right: 24, bottom: 20, left: 36 }

interface VerticalBarChartProps {
  data?: VerticalBarItem[]
  unit?: string
  formatter?: (value: string, unit: string) => string
}

const MINIMUM_HEIGHT = 6

export function VerticalBarChart({
  data = [],
  unit = 'USD',
  formatter = (value, unit) => `${unit} ${value}`
}: VerticalBarChartProps) {
  const { t } = useTranslation()
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.length === 0
  const dataSource = (isEmpty ? emptyData : data).sort((a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name))
  const colors = isEmpty ? emptyColors : chartColors
  const total = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const toolTipPercent = ((tooltipData?.data.value ?? 0) / total) * 100

  const handleMouseMove = (e: MouseEvent<SVGRectElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <>
      <ResponsiveContainer width={'100%'} height={320}>
        {({ width, height }) => {
          // space of chart
          const bounds = {
            width: width - (MARGIN.right + MARGIN.left),
            height: height - (MARGIN.top + MARGIN.bottom)
          }
          // scales
          const xTicks = dataSource.map((d) => String(d.name))
          const xScale = d3.scaleBand().domain(xTicks).range([0, bounds.width]).padding(0.4)
          const maxValue = Math.max(d3.max(dataSource, (d) => d.value)! * 1.2, 0)
          const minValue = Math.min(d3.min(dataSource, (d) => d.value)! * 1.2, 0)
          const yScale = d3.scaleLinear().domain([minValue, maxValue]).range([bounds.height, 0])

          // axis
          const xAxis = xScale.domain().map((label) => ({
            label,
            x: xScale(label)! + xScale.bandwidth() / 2
          }))
          const yAxis = yScale
            .ticks()
            .filter((_item, index) => index % 2 === 0)
            .map((label) => ({
              label,
              y: yScale(label)
            }))

          // bars
          const bars = dataSource.map((item) => {
            const height = Math.abs(yScale(item.value) - yScale(0))
            const adjustHeight = item.value === 0 ? 0 : Math.max(height, MINIMUM_HEIGHT)
            const yOffset = item.value > 0 ? -adjustHeight : 0
            return {
              x: xScale(item.name),
              y: yScale(0) + yOffset,
              width: xScale.bandwidth(),
              height: adjustHeight,
              ...item
            }
          })

          return (
            <svg width={width} height={height}>
              {/* X axis */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top + bounds.height})`} opacity={isEmpty ? 0 : 1}>
                {xAxis.map((item, index) => (
                  <text key={index} x={item.x} y={MARGIN.bottom} textAnchor={'middle'} fill={'#BCBEC9'}>
                    {capitalize(item.label)}
                  </text>
                ))}
              </g>

              {/* Y axis */}
              <g transform={`translate(0,${MARGIN.top})`}>
                <text x={MARGIN.left - 4} y={-14} textAnchor={'end'} fill={'#BCBEC9'}>
                  {unit}
                </text>

                {yAxis.map((item, index) => (
                  <text key={index} x={MARGIN.left - 4} y={item.y + 4} textAnchor={'end'} fill={'#BCBEC9'}>
                    {formatNumberShort(item.label)}
                  </text>
                ))}
              </g>

              {/* Cartesian Grid */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
                {yAxis.map((item, index) => (
                  <line
                    key={index}
                    x1={0}
                    x2={bounds.width}
                    y1={item.y}
                    y2={item.y}
                    stroke={'#BCBEC9'}
                    strokeWidth={1}
                    strokeDasharray={8}
                  />
                ))}
              </g>

              {/* Bar Items */}
              <g transform={`translate(${MARGIN.left},${MARGIN.top})`}>
                {bars.map((item, index) => (
                  <BarItem
                    key={index}
                    x={item.x!}
                    y={item.y!}
                    width={item.width}
                    height={item.height}
                    color={colors[index % colors.length]}
                    onMouseMove={(e) =>
                      handleMouseMove(e, {
                        name: item.name,
                        value: item.value,
                        color: colors[index % colors.length]
                      })
                    }
                    onMouseLeave={hideTooltip}
                  />
                ))}
              </g>

              {isEmpty && (
                <g transform={`translate(${width / 2}, ${height / 2})`}>
                  <text
                    className={'drop-shadow'}
                    y={6}
                    fill={'#fff'}
                    textAnchor={'middle'}
                    fontSize={'24'}
                    fontWeight={'bold'}
                  >
                    {t('NoData')}
                  </text>
                </g>
              )}
            </svg>
          )
        }}
      </ResponsiveContainer>
      {tooltipData && (
        <Tooltip top={tooltipData.top} left={tooltipData.left}>
          <div className={'flex items-center justify-between'}>
            <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
              <div className={'my-0.5 h-4 w-4 shrink-0 rounded'} style={{ backgroundColor: tooltipData.data.color }} />
              <TruncatedText as={'span'} className={'text-sm font-bold capitalize text-white'}>
                {tooltipData.data.name}
              </TruncatedText>
            </div>
          </div>
          <div className={'flex items-center justify-between'}>
            {toolTipPercent && (
              <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                {`${toolTipPercent.toFixed(1)}%`}
              </TruncatedText>
            )}
            <span className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
              {formatter(formatNumberShort(tooltipData.data.value), unit)}
            </span>
          </div>
        </Tooltip>
      )}
    </>
  )
}

interface BarItemProps {
  x: number
  y: number
  width: number
  height: number
  color: string
  onMouseMove: (e: MouseEvent<SVGRectElement>) => void
  onMouseLeave: () => void
}

function BarItem({ x, y, width, height, color, onMouseMove, onMouseLeave }: BarItemProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <rect
        width={width}
        height={height}
        fill={color}
        stroke={'#fff'}
        strokeWidth={1}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
    </g>
  )
}
