import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as d3 from 'd3'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import { defaultPreferences } from 'core/remodel/types/user'
import { fetchCurrentPreferences, userQuery } from '@/api/AccountService'
import { chartColors, emptyColors } from '@/constants/chartConfig'
import { cn } from '@/utils/classnames'
import { capitalize, formatNumber, formatNumberShort } from '@/utils/formatter'
import { useTooltip } from '@/hooks/useTooltip'
import { useAuthStore } from '@/store/authStore'
import TruncatedText from '@/components/base/TruncatedText'
import ResponsiveContainer from '@/components/chart/ResponsiveContainer'
import Tooltip from '@/components/chart/Tooltip'

export type StackedBarItem = {
  name: string
  value: number
}

type TooltipData = StackedBarItem & {
  color: string
}

const mockData: StackedBarItem[] = [
  { name: 'MyCollectables', value: 5000 },
  { name: 'MyFinances', value: 3000 },
  { name: 'MyProperties', value: 2000 },
  { name: 'MyBelongings', value: 1000 }
]

const emptyData: StackedBarItem[] = [
  { name: 'Mock 1', value: 60 },
  { name: 'Mock 2', value: 25 },
  { name: 'Mock 3', value: 20 },
  { name: 'Mock 4', value: 5 }
]

const NARROWEST_WIDTH = 12

interface StackedBarChartProps {
  data?: StackedBarItem[]
  unit?: string
  formatter?: (value: string, unit: string) => string
  max?: number
  hiddenUnassigned?: boolean
  unassignedLabel?: string
  isMergedOthers?: (data: StackedBarItem) => boolean
}

export function StackedBarChart({
  data = [],
  unit = 'USD',
  formatter = (value, unit) => `${unit} ${value}`,
  max,
  hiddenUnassigned = false,
  unassignedLabel,
  isMergedOthers
}: StackedBarChartProps) {
  const database = useAuthStore((state) => state.database)
  const { t } = useTranslation()
  const { data: preferences = defaultPreferences } = useSWR(
    [userQuery.currentPreferences],
    fetchCurrentPreferences(database!)
  )
  const { tooltipData, showTooltip, hideTooltip } = useTooltip<TooltipData>()
  const isEmpty = data.every(({ value }) => value === 0)

  const dataSource = useMemo(() => {
    if (isEmpty) return emptyData

    const sortedData = data.sort((a, b) => b.value - a.value)

    if (isMergedOthers) {
      const significantItems = sortedData.filter((item) => !isMergedOthers(item))
      const smallItems = sortedData.filter((item) => isMergedOthers(item))

      if (significantItems.length > 7 || smallItems.length > 0) {
        const topItems = significantItems.slice(0, 6)
        const remainingItemsSum = d3.sum(significantItems.slice(6), (d) => d.value)
        const smallItemsSum = d3.sum(smallItems, (d) => d.value)
        const others = { name: t('Others'), value: remainingItemsSum + smallItemsSum }
        return [...topItems, others]
      }
    }

    if (sortedData.length > 7) {
      const topItems = sortedData.slice(0, 6)
      const remainingSum = d3.sum(sortedData.slice(6), (d) => d.value)
      const others = { name: t('Others'), value: remainingSum }
      return [...topItems, others]
    }
    return sortedData
  }, [data, isEmpty, t, isMergedOthers])

  const colors = isEmpty ? emptyColors : chartColors
  const sum = useMemo(() => d3.sum(dataSource, (d) => d.value), [dataSource])
  const total = useMemo(() => Math.max(sum, max || 1), [max, sum])
  const toolTipPercent = ((tooltipData?.data.value ?? 0) / total) * 100

  const handleMouseMove = (e: MouseEvent<SVGRectElement>, data: TooltipData) => {
    const left = e.clientX + 260
    const covered = left - window.innerWidth
    showTooltip({
      top: e.clientY - 50,
      left: covered > 0 ? e.clientX - covered : e.clientX,
      data
    })
  }

  return (
    <>
      <ResponsiveContainer width={'100%'} height={50}>
        {({ width, height }) => {
          const percent = d3.scaleLinear().domain([0, total]).range([0, width])
          const hasUnassigned = max !== undefined && total > sum
          const unassigned = hasUnassigned ? [{ name: unassignedLabel ?? t('Unassigned'), value: total - sum }] : []
          const barList = [...dataSource, ...unassigned]

          const widthList: number[] = []
          barList.reduceRight((expandedSpace, { value }, index) => {
            const barWidth = percent(value)
            const shrinkSpace = expandedSpace / (index + 1)
            // If < NARROWEST_WIDTH, expand.
            // If < NARROWEST_WIDTH after shrinking, don't change
            // Else, shrink.
            if (barWidth === 0) {
              widthList[index] = 0
              return expandedSpace
            } else if (barWidth < NARROWEST_WIDTH) {
              widthList[index] = NARROWEST_WIDTH
              return expandedSpace + (NARROWEST_WIDTH - barWidth)
            } else if (barWidth - shrinkSpace < NARROWEST_WIDTH) {
              widthList[index] = barWidth
              return expandedSpace
            } else {
              widthList[index] = barWidth - shrinkSpace
              return (expandedSpace * index) / (index + 1)
            }
          }, 0)

          const xList: number[] = []
          widthList.reduce((acc, width, index) => {
            xList[index] = acc
            return acc + width
          }, 0)

          return (
            <svg width={width} height={height}>
              {barList.map((item, index) => {
                const isUnassigned = [t('Unassigned'), t('Unallocated')].includes(item.name)
                const isHidden = hiddenUnassigned && isUnassigned
                return (
                  <BarItem
                    key={index}
                    x={xList[index]}
                    width={widthList[index]}
                    color={!isUnassigned ? colors[index % colors.length] : emptyColors[0]}
                    label={capitalize(item.name)}
                    value={formatter(formatNumber(item.value, preferences.numberFormat, { digits: 0 }), unit)}
                    isEmpty={isHidden || isEmpty}
                    onMouseMove={(e) =>
                      handleMouseMove(e, {
                        name: item.name,
                        value: item.value,
                        color: !isUnassigned ? colors[index % colors.length] : emptyColors[0]
                      })
                    }
                    onMouseLeave={hideTooltip}
                  />
                )
              })}
            </svg>
          )
        }}
      </ResponsiveContainer>

      {tooltipData &&
        createPortal(
          <Tooltip top={tooltipData.top} left={tooltipData.left}>
            <div className={'flex items-center justify-between'}>
              <div className={'flex min-w-0 flex-1 items-center gap-x-1'}>
                <div
                  className={'my-0.5 h-4 w-4 shrink-0 rounded'}
                  style={{ backgroundColor: tooltipData.data.color }}
                />
                <TruncatedText as={'span'} className={'text-sm font-bold capitalize text-white'}>
                  {tooltipData.data.name}
                </TruncatedText>
              </div>
            </div>
            <div className={'flex items-center justify-between'}>
              {toolTipPercent && (
                <TruncatedText as={'span'} className={'ml-5 text-sm font-bold capitalize text-white'}>
                  {`${toolTipPercent.toFixed(1)}%`}
                </TruncatedText>
              )}
              <span className={'flex-1 shrink-0 text-right text-sm font-bold text-white'}>
                {formatter(formatNumberShort(tooltipData.data.value), unit)}
              </span>
            </div>
          </Tooltip>,
          document.body
        )}
    </>
  )
}

interface BarItemProps {
  x: number
  width: number
  color: string
  label: string
  value: string
  isEmpty: boolean
  onMouseMove: (e: MouseEvent<SVGRectElement>) => void
  onMouseLeave: () => void
}

const barItemMargin = 8

function BarItem({ x, width, color, label, value, isEmpty, onMouseMove, onMouseLeave }: BarItemProps) {
  const ref = useRef<HTMLParagraphElement>(null)
  const [textWidth, setTextWidth] = useState<number>(0)
  const opacity = useMemo(() => {
    const isOverflow = width < textWidth + barItemMargin * 2
    return isOverflow || isEmpty ? 0 : 1
  }, [isEmpty, textWidth, width])

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const entry = entries[0]
        setTextWidth(entry.contentRect.width)
      }
    })
    if (ref.current) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return (
    <g transform={`translate(${x},0)`}>
      <rect
        className={cn(isEmpty && 'pointer-events-none')}
        width={width}
        height={'100%'}
        fill={color}
        stroke={'#fff'}
        strokeWidth={1}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <foreignObject
        className={'pointer-events-none'}
        width={Math.max(width - barItemMargin * 2, 0)}
        height={'100%'}
        x={barItemMargin}
        opacity={opacity}
      >
        <div className={'flex h-full flex-col items-start justify-center text-[13px] font-bold text-white'}>
          <TruncatedText>{label}</TruncatedText>
          <p className={'whitespace-nowrap'} ref={ref}>
            {value}
          </p>
        </div>
      </foreignObject>
    </g>
  )
}

interface ComparisonStackedBarChartProps {
  assetsData: StackedBarItem[]
  liabilitiesData: StackedBarItem[]
  assetsConfig?: Omit<StackedBarChartProps, 'data'>
  liabilitiesConfig?: Omit<StackedBarChartProps, 'data'>
}

export function ComparisonStackedBarChart({
  assetsData,
  liabilitiesData,
  assetsConfig,
  liabilitiesConfig
}: ComparisonStackedBarChartProps) {
  const { t } = useTranslation()

  const calculateTotal = (data: StackedBarItem[]) => data.reduce((sum, item) => sum + Math.abs(item.value), 0)

  const totalAssets = useMemo(() => calculateTotal(assetsData), [assetsData])
  const totalLiabilities = useMemo(() => calculateTotal(liabilitiesData), [liabilitiesData])
  const totalAmount = totalAssets + totalLiabilities

  const calculateWidthRatio = useCallback((amount: number, total: number): number => {
    if (amount === 0) return 100
    if (amount >= total / 2) return 100
    if (amount > total) return 100 // invalid usage

    const ratio = amount / total
    const SCALE_THRESHOLD = 0.3

    if (ratio < SCALE_THRESHOLD) {
      const logScale = d3.scaleLog().domain([0.0000001, SCALE_THRESHOLD]).range([0, SCALE_THRESHOLD]).clamp(true)
      return logScale(ratio) * 100
    }

    return ratio * 100
  }, [])

  const widthRatios = {
    assets: `${calculateWidthRatio(totalAssets, totalAmount)}%`,
    liabilities: `${calculateWidthRatio(totalLiabilities, totalAmount)}%`
  }

  return (
    <div className={'space-y-2'}>
      <h4 className={'text-sm font-medium uppercase'}>{t('Assets')}</h4>
      <div style={{ width: widthRatios.assets }}>
        <StackedBarChart data={assetsData} {...assetsConfig} />
      </div>

      <h4 className={'text-sm font-medium uppercase'}>{t('Liabilities')}</h4>
      <div style={{ width: widthRatios.liabilities }}>
        <StackedBarChart data={liabilitiesData} {...liabilitiesConfig} />
      </div>
    </div>
  )
}
